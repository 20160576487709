import React, { useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import SEO from "../components/globals/seo";
import Layout from "../components/containers/layout";
import Section from "../components/containers/section";
import Container from "../components/containers/container";
import Box from "../components/containers/box";
import BlogBanner from "../components/banners/blogBanner";
import BlogList from "../components/blog/blogList";
import Heading from "../components/typography/heading";
import Text from "../components/typography/text";
import Image from "../components/globals/image";
import {
  FacebookLogo,
  TwitterLogo,
} from "../components/svg/logos";
import Video from "../components/video/video";
import { buildDate } from "../utils/helperFunctions";
import Link from "../components/links/link";
import HTMLRenderer from "../utils/htmlRenderer";

const FacebookShareButton = styled.button`
  background-color: transparent;
  border: none;

  :hover {
    cursor: pointer;
  }
`;

const LogoLinks = styled(Box)`
  max-width: 992px;
  padding-top: 30px;

  > * {
    display: inline-block;
    margin-right: 30px;
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    vertical-align: middle;
  }

  @media (min-width: ${p => p.theme.screenM}) {
    margin: auto;
    padding-top: 60px;
  }
`;

const DateWrap = styled(Box)`
  font-size: ${p => p.theme.rem16};
`;

const BannerContainer = styled(Container)`
  max-width: 992px;
  padding-top: 46px;
  padding-bottom: 160px;

  > * {
    text-align: left;
  }

  @media (min-width: ${p => p.theme.screenS}) {
    padding-top: 74px;
    padding-bottom: 212px;  
  }
`;

const BlogPostPage = ({ data, theme }) => {
  // Attach twitter widgets once on page load.
  useEffect(() => {
    const script = document.createElement(`script`);
    script.src = `https://platform.twitter.com/widgets.js`;
    document.getElementsByClassName(`twitter-embed`)[0].appendChild(script);
  }, []);

  // Grabbing data from Prismic
  const {
    uid,
    data: { date: postDate, yt_video_id: videoID, title, content, description, image, hashtags },
  } = data.activePost.edges[0].node;
  const { edges: recentPostsArray } = data.recentPosts;

  if (!content) return null;

  // Twitter hashtag string logic
  const hashtagsParam = hashtags !== null ? `&hashtags=${hashtags}` : ``;
  // Twitter crashes on share click without encoding (% was causing issues in Prismic title)
  const urlEncodeTitle = (titleArg) => {
    const titleArr = titleArg.split(``);
    const titleArrEscaped = titleArr.map((character) => {
      if (character === ` `) return `%20`;
      if (character === `%`) return `%25`;

      return character;
    });

    return titleArrEscaped.join(``);
  };
  const ogTitle = title.text.replace(`-`, ` `);

  return (
    <Layout navbarPadOnly>
      <SEO
        ogUrl={`https://toptekkers.com/blog/${uid}`}
        ogImage={image.url}
        title={ogTitle}
        path={`/blog/${uid}`}
        description={description || title.text}
      />

      <BlogBanner>
        <BannerContainer>
          {postDate
            && (
              <DateWrap color={theme.white} mb={[`20px`, null, `40px`]}>
                {buildDate(postDate)}
              </DateWrap>
            )}

          <Heading color={theme.white} maxWidth="none" mb="0" textAlign="left">
            {title.text}
          </Heading>
        </BannerContainer>
      </BlogBanner>

      {/* Highlight */}
      <Section pt="30px" pb={[40, null, 70]}>
        <Container>
          {/* image */}
          <Box
            mt={[-160, null, -150]}
            maxHeight="675px"
            overflow="hidden"
            borderRadius="3px"
            mb={[`40px`, null, `65px`]}
          >
            <Image
              fluid={image.localFile.childImageSharp.fluid}
              alt={image.alt}
              width="100%"
              borderRadius="3px"
            />
          </Box>

          <Box mx={[`0`, `auto`]} maxWidth="992px">
            <HTMLRenderer html={content.html} />

            {videoID && (
              <Video>
                <iframe
                  title="TopTekkers blog related video"
                  src={`https://www.youtube.com/embed/${videoID}`}
                  width="100%"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </Video>
            )}

            <LogoLinks>
              <Text mb="0">Share</Text>
              {/* Twitter Share */}
              <div className="twitter-embed">
                <a
                  href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Ftoptekkers.com%2Fblog%2F${uid}&text=${urlEncodeTitle(
                    title.text,
                  )}&via=TopTekkersApp${hashtagsParam}`}
                >
                  <TwitterLogo size="s" fill={theme.blueDark} />
                </a>
              </div>

              {/* Both Facebook and LinkedIn:
            Behavior: 
            When setting url param to active page the share button throws a 404 (have tested with build/serve)
            When setting url param to any live page it it is using info from index page
            Using URL params of any type is showing no difference from the index page information.
            
            Expected:
            Share buttons use og tags (set in each page via SEO component) to populate a share (LinkedIn customisation very limited without business acc) */}

              {/* Facebook Share */}
              <FacebookShareButton
                onClick={() => {
                  FB.ui(
                    {
                      method: `share`,
                      href: `https://toptekkers.com/blog/${uid}`,
                    },
                    function() {},
                  );
                }}
              >
                <FacebookLogo size="s" fill={theme.blueDark} />
              </FacebookShareButton>

              {/* LinkedIn Share */}
              {/* <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Ftoptekkers.com%2Fblog%2F${uid}&title=${title.text}`}
              target="_blank"
              rel="noopener noreferer"
            >
              <LinkedInLogo size="s" fill={theme.blueDark} />
            </a> */}
            </LogoLinks>
          </Box>
        </Container>
      </Section>

      <Section pt={[`40px`, null, `70px`]}>
        <Container>
          <BlogList listItems={recentPostsArray} />
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query($currentUID: String!) {
    activePost: allPrismicBlog(filter: { uid: { eq: $currentUID } }) {
      edges {
        node {
          uid
          data {
            date
            yt_video_id
            hashtags
            content_type
            description
            title {
              text
            }
            content {
              html
            }
            image {
              alt
              localFile {
                ...imageFragmentMax
              }
            }
          }
        }
      }
    }
    recentPosts: allPrismicBlog(
      sort: { fields: data___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          uid
          data {
            date
            description
            title {
              text
            }
            image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 370) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

BlogPostPage.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(BlogPostPage);
